<template>
  <article>
    <header>
      <h1 class="h2 mb-3 emobg-title-xxl">
        {{ legalDocument.document_name }}
      </h1>

      <main
        class="emobg-body-m-regular"
        v-html="compiledDescription(legalDocument.document)"
      />
    </header>
  </article>
</template>

<script>
import Marked from 'marked';

export default {
  name: 'ShowLegalDocument',
  props: {
    legalDocument: {
      type: Object,
      default: null,
    },
  },
  methods: {
    compiledDescription(description = '') {
      return Marked(description, { sanitize: true, breaks: true });
    },
  },
};

</script>
