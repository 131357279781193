<template>
  <section class="LegalDocuments">
    <header>
      <nav class="Navbar Navbar--shadow Navbar--fixed d-flex justify-content-center align-items-center">
        <a href="/">
          <Logo class="d-flex" />
        </a>
      </nav>
    </header>
    <div class="LayoutPage">
      <NotificationListManager />
      <div class="LegalDocumentsLayout">
        <div class="LegalDocumentsLayout--menu">
          <h3 class="mb-3">
            {{ $t('views.legal_documents.menu_title') }}
          </h3>
          <ul
            v-for="(group, key) in groupItemsBy(documents)"
            :key="`document-group-${key}`"
            class="mb-0"
          >
            <li
              v-for="(groupItem, index) in group"
              :key="`document-${groupItem.uuid}-${index}`"
              class="mb-2"
            >
              <RouterLink
                class="emobg-text-link"
                :class="isCurrentDocument(groupItem) ? 'link--main' : 'link--secondary'"
                :to="getRouteObject(groupItem)"
              >
                {{ groupItem.document_name || groupItem.internal_name }}
              </RouterLink>
            </li>
          </ul>
        </div>
        <ShowLegalDocument
          v-if="currentDocument"
          class="LegalDocumentsLayout--content "
          :legal-document="currentDocument"
        />
      </div>
    </div>
  </section>
</template>

<script>
import get from 'lodash/get';
import find from 'lodash/find';
import { mapActions, mapState } from 'vuex';
import { getQueryParams } from '@emobg/web-utils';
import languageService from '@/services/Language';
import LegalDocumentsRoutesNames from '@/domains/Document/Legal/router/routes-names';
import { ERROR_MESSAGES } from '@/handlers/errorHandler.const';
import { groupItemsBy } from '@/helpers/legalDocuments/legalDocumentsHelpers';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import NotificationListManager from '@/components/NotificationList/NotificationListManager';
import Logo from '@/components/Logo/Logo';
import LoaderMixin from '@/mixins/Loader';
import ShowLegalDocument from './ShowLegalDocument';
import * as LegalDocumentsModule from './store/LegalDocumentsModule';

export default {
  name: 'LegalDocuments',
  components: {
    ShowLegalDocument,
    NotificationListManager,
    Logo,
  },
  mixins: [
    LoaderMixin,
  ],
  setup() {
    const { notifyError } = useNotifications();
    return {
      notifyError,
    };
  },
  data() {
    return {
      currentDocument: undefined,
      csOperatorUUID: undefined,
      legalDocumentUUID: undefined,
      legalDocumentsFrom: undefined,
      userUuidQueryParam: undefined,
    };
  },
  computed: {
    ...mapState(LegalDocumentsModule.NAMESPACE, {
      status: state => state.STATUS,
      error: state => state.error,
      documents: state => state.data,
    }),
  },
  watch: {
    $route() {
      this.setParams();
      this.currentDocument = find(this.documents, ['uuid', this.legalDocumentUUID]);
    },
  },
  beforeCreate() {
    this.$store.registerModule(LegalDocumentsModule.NAMESPACE, LegalDocumentsModule.store);
  },
  created() {
    this.setParams();
    this.loadDocuments();
  },
  beforeDestroy() {
    this.$store.unregisterModule(LegalDocumentsModule.NAMESPACE);
  },
  methods: {
    groupItemsBy,

    ...mapActions(LegalDocumentsModule.NAMESPACE, ['getPrivateLegalDocuments', 'getPublicLegalDocuments']),
    setParams() {
      this.legalDocumentUUID = get(this, '$route.query.uuid');
      this.csOperatorUUID = get(this, '$route.query.csOperator');
      this.userUuidQueryParam = get(this, '$route.query.userUuid');
    },
    async loadDocuments() {
      const language = languageService.getLanguage();

      if (this.userUuidQueryParam) {
        await this.getPrivateLegalDocuments({
          userUuid: this.userUuidQueryParam,
          language,
        });
      } else {
        await this.getPublicLegalDocuments({
          csOperatorUUID: this.csOperatorUUID,
          language,
        });
      }
      if (this.status.LOADED) {
        this.onSuccessLoadDocuments();
      }
      if (this.status.ERROR) {
        this.onErrorLoadDocuments();
      }
      this.toggleLoaderStatus(false);
    },
    onSuccessLoadDocuments() {
      this.currentDocument = find(this.documents, ['uuid', this.legalDocumentUUID]);
      if (!this.currentDocument) {
        this.$router.push(this.getRouteObject(this.documents[0]));
      }
    },
    onErrorLoadDocuments() {
      const isHtmlError = get(this.error, 'response.headers.content-type') === 'text/html';
      const message = isHtmlError
        ? this.$t('notifications.whooops')
        : get(this.error, 'response.data.message', ERROR_MESSAGES.generic);

      this.notifyError({
        delayToClose: -1,
        text: message,
        textAction: this.$t('buttons.ok'),
        action: () => {
          this.$router.push({ name: LegalDocumentsRoutesNames.legalDocuments });
        },
      });
    },
    isCurrentDocument(document) {
      const uuidCurrentLegalDocument = get(this, 'currentDocument.uuid');
      const uuidMenuDocument = get(document, 'uuid');

      return uuidCurrentLegalDocument === uuidMenuDocument;
    },
    getRouteObject(item) {
      return {
        name: LegalDocumentsRoutesNames[item.internal_name],
        query: {
          userUuid: this.userUuidQueryParam,
          uuid: item.uuid,
          csOperator: item.cs_operator_uuid,
          from: getQueryParams(item.url).from,
          role: getQueryParams(item.url).role,
        },
      };
    },
  },
};
</script>
