import isString from 'lodash/isString';
import {
  STORE_REQUEST_STATUS,
  initialState,
  setData,
  setError,
  setStatus,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import languageService from '@/services/Language';

export const NAMESPACE = 'LegalDocumentsModule';
const MUTATIONS = {
  setStatus: 'setStatus',
  setError: 'setError',
  setData: 'setData',
};
export const ACTIONS = {
  getPublicLegalDocuments: 'getPublicLegalDocuments',
  getPrivateLegalDocuments: 'getPrivateLegalDocuments',
};

const mutations = {
  setStatus,
  setError,
  setData,
};
const state = {
  ...initialState,
};

const actions = {
  async [ACTIONS.getPrivateLegalDocuments]({ commit }, {
    language,
    userUuid,
  }) {
    if (!isString(userUuid)) {
      return;
    }
    commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loading);

    try {
      const response = await external.userCompanyUserDocuments.getUserLegalDocuments(userUuid, language);
      commit(MUTATIONS.setData, response.documents);
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loaded);
    } catch (error) {
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.error);
      commit(MUTATIONS.setError, error);
    }
  },
  async [ACTIONS.getPublicLegalDocuments]({ commit }, {
    csOperatorUUID,
    language: locale,
  }) {
    if (!isString(csOperatorUUID)) {
      return;
    }
    commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loading);

    try {
      const defaultLanguage = languageService.getLanguageCodeFromLocale(languageService.defaultLang);
      const language = languageService.getLanguageCodeFromLocale(locale) || defaultLanguage;

      const { documents = [] } = (await external.userCompanyCSOperator.getLegalDocuments(csOperatorUUID, 'v4', language) || {});

      commit(MUTATIONS.setData, documents);
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loaded);
    } catch (error) {
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.error);
      commit(MUTATIONS.setError, error);
    }
  },
};
export const store = {
  namespaced: true,
  actions,
  mutations,
  state,
};
